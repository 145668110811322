import React from "react"

import { withWishlistCard } from "./withWishlistCard"
import { WishlistList } from "../List/WishlistList"

export const WishlistCard = withWishlistCard(
  ({ items, handleDelete, loading }): JSX.Element => (
    <WishlistList items={items} handleDelete={handleDelete} loading={loading} />
  )
)
